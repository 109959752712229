import React from "react"
import { Refresh } from "./icons"

function Loading() {
  return (
    <div className="flex flex-col items-center justify-center h-64 gap-4">
      <div className="animate-spin w-min">
        <Refresh className="w-12 h-12" />
      </div>
      <div className="animate-pulse">Loading ...</div>
    </div>
  )
}

export default Loading
