import React, { useState } from "react"
import { useQuery, useMutation, gql } from "@apollo/client"

import { Refresh } from "./icons"
import Loading from "../components/loading"

let DisplayBuild = ({ buildId, orgId, className }) => {
  let [muteLoading, setMuteLoading] = useState(false)
  const [rebuildSite] = useMutation(REBUILD_SITE, {
    variables: { orgId },
    refetchQueries: ["GetRecentBuild"],
  })
  const {
    loading,
    error,
    data,
    startPolling,
    stopPolling,
  } = useQuery(GET_SPECIFIED_BUILD, { variables: { buildId } })
  if (loading) {
    if (muteLoading) setMuteLoading(false)
    return (
      <Loading/>
    )
  }
  if (error) return <p>Error :(</p>
  let siteBuild = data?.getSiteBuild
  let colorClasses = ""
  let text = ""
  if (!siteBuild.done) {
    startPolling(1000 * 25) // Poll every 25 seconds
    colorClasses =
      "border-yellow-900 bg-yellow-100 text-yellow-900 animate-pulse"
    text = "Site is building"
  } else {
    stopPolling()
    if (siteBuild.error) {
      colorClasses = "border-red-900 bg-red-100 text-red-900"
      text = "Site Build Failure"
    } else {
      colorClasses = "border-green-900 bg-green-100 text-green-900"
      text = "Site Build Successful"
    }
  }
  async function clickRebuild() {
    setMuteLoading(true)
    await rebuildSite()
  }
  let busy = !siteBuild.done || muteLoading
  return (
    <section className={`pt-6 pb-1 ${className}`}>
      <h3>Website Deployment</h3>
      <div
        className={`relative flex justify-between my-3.5 p-1.5 gap-1.5 border rounded ${colorClasses}`}
      >
        <div className="w-full p-1">
          <p className="font-semibold">{text}</p>
          <p className="text-stone-500 text-sm">
            Deployed on{" "}
            {new Date(siteBuild.created_at).toLocaleString("en-US", {
              dateStyle: "full",
              timeStyle: "long",
            })}
          </p>
        </div>
        <button
          onClick={clickRebuild}
          disabled={busy}
          className={`absolute top-0 right-0 btn btn-secondary text-stone-700 rounded-none rounded-bl-md p-1.5 ${
            !busy ? "" : "bg-transparent pointer-events-none"
          }`}
        >
          <div className="w-16 h-5">
            {!busy ? (
              "Rebuild"
            ) : (
              <div className="flex items-center justify-end w-full h-full">
                <div className="animate-spin text-stone-700 bg-transparent -mt-0.5">
                  <Refresh />
                </div>
              </div>
            )}
          </div>
        </button>
      </div>
    </section>
  )
}

let SiteBuild = ({ orgId, className }) => {
  const { loading, error, data } = useQuery(GET_RECENT_BUILD, {
    variables: { orgId },
  })
  if (loading)
    return (
      <Loading/>
    )
  if (error) return <p>Error :(</p>
  let recentBuild = data?.getRecentBuild
  if (!recentBuild?.id)
    return (
      <p className={`text-stone-800 pt-4 text-sm italic ${className}`}>
        Organization is not connected to a site
      </p>
    )

  return (
    <DisplayBuild
      buildId={recentBuild.id}
      orgId={orgId}
      className={`${className}`}
    />
  )
}

export default SiteBuild

const GET_RECENT_BUILD = gql`
  query GetRecentBuild($orgId: Float!) {
    getRecentBuild(orgId: $orgId) {
      id
    }
  }
`
const GET_SPECIFIED_BUILD = gql`
  query GetSpecifiedBuild($buildId: String!) {
    getSiteBuild(buildId: $buildId) {
      id
      done
      error
      created_at
    }
  }
`

const REBUILD_SITE = gql`
  mutation RebuildSite($orgId: Float!) {
    rebuildSite(orgId: $orgId)
  }
`
