import React, { useEffect } from "react"
import { useQuery, gql } from "@apollo/client"
import Loading from "../components/loading"
import { ContactFields } from "./contact-fields"
import FormField from "./form-field"
import StaffList from "./staff-list"
import SiteBuild from "./site-build"
import { Details } from "./resp-details"
import ImgUpload from "./img-upload"


let OrgInfoFields = ({
  className,
  org: {
    id,
    tagline,
    about,
    description,
    gaId,
    dominantColor,
    complimentColor,
  },
}) => {
  let colorOpts = Object.fromEntries(
    [
      "slate",
      "gray",
      "zinc",
      "neutral",
      "stone",
      "red",
      "orange",
      "amber",
      "yellow",
      "lime",
      "green",
      "emerald",
      "teal",
      "cyan",
      "sky",
      "blue",
      "indigo",
      "violet",
      "purple",
      "fuchsia",
      "pink",
      "rose",
    ].map(x => [x, x])
  )
  return (
    <section className={`pt-6 pb-1 ${className || ""}`}>
      <Details title="Brokerage Info" keepOpen="tablet">
        <FormField
          input={{
            id: "tagline",
            type: "textarea",
            autoComplete: "off",
            placeholder: "Company Tagline",
            maxLength: "200",
          }}
          initValue={tagline ? tagline.trim() : ""}
          label="Tagline"
          gqlKey="org_tagline"
          id={id}
        />
        <FormField
          input={{
            id: "gaId",
            type: "text",
            autoComplete: "off",
            placeholder: "G-XXXXXX",
          }}
          initValue={gaId ? gaId.trim() : ""}
          label="Google Analytics ID"
          gqlKey="org_gaId"
          id={id}
        />
        <FormField
          input={{
            id: "dominantColor",
            type: "select",
          }}
          initValue={dominantColor || ""}
          options={colorOpts}
          label="Site's Dominant Color"
          gqlKey="org_domCol"
          id={id}
        />
        <FormField
          input={{
            id: "complimentColor",
            type: "select",
          }}
          initValue={complimentColor || ""}
          options={colorOpts}
          label="Site's Compliment Color"
          gqlKey="org_comCol"
          id={id}
        />
        <FormField
          input={{
            id: "description",
            type: "textarea",
            autoComplete: "off",
            placeholder: "Enter Website SEO Description",
            maxLength: "200",
            className: "h-24",
          }}
          initValue={description ? description.trim() : ""}
          label="Site Description"
          gqlKey="org_desc"
          id={id}
          className="tablet:col-span-2"
        />
        <FormField
          input={{
            id: "about",
            type: "textarea",
            autoComplete: "off",
            placeholder: "Enter Company Bio",
            maxLength: "5000",
            className: "h-24",
          }}
          initValue={about ? about.trim() : ""}
          label="About"
          gqlKey="org_about"
          id={id}
          className="tablet:col-span-2"
        />
      </Details>
    </section>
  )
}

export const OrgDetails = ({ org }) => {
  let { contact, id, imageUrl } = org
  return (
    <div className="tablet:grid-cols-2 tablet:gap-y-4 desktop:gap-10 grid grid-cols-1 gap-2">
      <div className="flex justify-center col-span-1">
        <ImgUpload 
          alt={`Brokerage's logo`} 
          imageUrl={imageUrl || ""}
          entityId={id} 
          entityType="org"
          widgetOpts={{
            clientAllowedFormats: ["svg"],
            sources: ["local"]
          }}
        />
      </div>
      <div className="tablet:mt-3 col-span-1">
        <FormField
          input={{
            id: "name",
            type: "text",
            autoComplete: "organization",
            placeholder: "My Brokerage Inc.",
          }}
          initValue={org.name.trim()}
          label="Name"
          gqlKey="org_name"
          id={org.id}
        />
      </div>
      <OrgInfoFields
        org={org}
        className="tablet:col-span-2 desktop:col-span-1"
      />
      <ContactFields
        contact={contact}
        className="tablet:col-span-2 desktop:col-span-1"
      />
      <StaffList orgId={id} className="tablet:col-span-2 desktop:col-span-1" />
      <SiteBuild orgId={id} className="tablet:col-span-2 desktop:col-span-1" />
    </div>
  )
}

const Org = ({ setTitle }) => {
  const title = "Brokerage"
  useEffect(() => {
    setTitle(`Your ${title}`)
    return () => {
      setTitle("")
    }
  }, [setTitle])
  const { loading, error, data } = useQuery(USER_ORG)
  if (loading) return <Loading />
  if (error) return <p>Error :(</p>
  let {
    curUser: {
      staff: { org },
    },
  } = data
  if (!org) return <p>You are not a part of any organization</p>
  return (
    <>
      <h1>{title}</h1>
      <OrgDetails org={org} />
    </>
  )
}

export let innerQuery = `
            id
            name
            imageUrl
            tagline
            about
            description
            gaId
            dominantColor
            complimentColor
            contact{
              id
              fb
              ig
              linkedIn
              yt
              twitter
              cell
              business
              email
              addr
            }
`

const USER_ORG = gql`
    {
      curUser {
        id
        staff {
          id
          org {${innerQuery}}
        }
      }
    }
`

export default Org
