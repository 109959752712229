import React from "react"
import { Helmet } from "react-helmet"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"

import App from "../components/app"
import Login from "../components/login"

import { performLogin, performRefresh, performLogout } from "../services/auth"
import Loading from "../components/loading"

// 2 minutes in ms
const minMs2 = 2 * 60 * 1000

class Main extends React.Component {
  gqlLink = createHttpLink({
    uri: `${process.env.GATSBY_API_URL}/graphql`,
    credentials: "include",
  })
  errorLink = onError(({ networkError }) => {
    if (networkError?.statusCode === 401) {
      this.handleLogout()
    }
  })
  gqlClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([this.errorLink, this.gqlLink]),
  })
  constructor(props) {
    super(props)
    this.state = {
      pageTitle: "",
    }
    this.timer = null

    this.handleLogin = ({ email, password } = {}) => {
      return performLogin({ email, password })
        .then(expires => {
          this.handleRefresh(expires)
          this.setState({ loggedIn: true })
          return
        })
        .catch(err => {
          if (!err?.isAxiosError) {
            console.log(err)
          }
          this.handleLogout()
          throw err
        })
    }

    this.handleRefresh = expires => {
      let expDate = new Date(expires)
      let sleepTime = expDate.valueOf() - Date.now() - minMs2
      this.timer = setTimeout(() => {
        performRefresh().then(this.handleRefresh).catch(this.handleLogout)
      }, Math.max(sleepTime, 0))
    }

    this.handleLogout = () => {
      clearTimeout(this.timer)
      performLogout()
        .then(() => {
          this.setState({ loggedIn: false })
          return this.gqlClient.clearStore()
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  componentDidMount() {
    // Ignore initial error
    this.handleLogin().catch(err => {})
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  updateTitle = pageTitle => {
    if (this.state.pageTitle !== pageTitle) this.setState({ pageTitle })
  }

  getBody() {
    if (this.state.loggedIn === undefined) {
      return <Loading />
    } else if (this.state.loggedIn) {
      return (
        <ApolloProvider client={this.gqlClient}>
          <App logout={this.handleLogout} setTitle={this.updateTitle} />
        </ApolloProvider>
      )
    } else {
      return <Login login={this.handleLogin} setTitle={this.updateTitle} />
    }
  }

  render() {
    let pageTitle = this.state.pageTitle ? `${this.state.pageTitle} | ` : ""
    return (
      <div>
        <Helmet>
          <html lang="en" />
          <title>{`${pageTitle}ModernRealtor`}</title>
          <meta http-equiv="Content-Type" content="text/html; charSet=utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        {this.getBody()}
      </div>
    )
  }
}

export default Main
