import React from "react"
import FormField from "./form-field"
import { Details } from "./resp-details"

export const StaffFields = ({ staff, className }) => {
  let {
    id,
    about,
    title,
    displayOnPv,
    org: { name },
  } = staff
  return (
    <section className={`pt-6 pb-1 ${className || ""}`}>
      <Details title="Staff Info" keepOpen="tablet">
        <div className="tablet:col-span-2 pt-2 border-b border-dotted">
          <p className="text-xs font-thin capitalize">Name:</p>
          <p className="pb-1">{name}</p>
        </div>
        <FormField
          input={{
            id: "title",
            type: "text",
            autoComplete: "organization-title",
            placeholder: "e.g. Broker, Sales Representative, etc",
          }}
          initValue={title ? title.trim() : ""}
          label="Title"
          gqlKey="staff_title"
          id={id}
          className="border-b border-dotted"
        />
        <FormField
          input={{
            id: "displayOnPv",
            type: "select",
          }}
          initValue={!!displayOnPv}
          options={{ true: "Yes", false: "No" }}
          label="Display staff on public site?"
          gqlKey="staff_displayPv"
          id={id}
          className="border-b border-dotted"
        />
        <FormField
          input={{
            id: "about",
            type: "textarea",
            autoComplete: "off",
            placeholder: "Enter Public Staff Bio",
            maxLength: "5000",
            className: "h-24 desktop:h-48",
          }}
          initValue={about ? about.trim() : ""}
          label="About"
          gqlKey="staff_about"
          id={id}
          className="tablet:col-span-2 border-b border-dotted"
        />
      </Details>
    </section>
  )
}
