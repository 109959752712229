import React, { useState, useEffect } from "react"

import Layout from "./layout"
import Profile from "./profile"
import ProfileList from "./profile-list"
import Org from "./org"
import OrgList from "./org-list"

const Dashboard = ({ setTitle }) => {
  useEffect(() => {
    setTitle("")
    return () => {
      setTitle("")
    }
  }, [setTitle])
  return (
    <>
      <h1> Dashboard </h1>
      <div className="pt-16">
        <p>Nothing to see here! &#127774;</p>
      </div>
    </>
  )
}

const InnerComponent = ({ page, logout, setTitle }) => {
  switch (page) {
    case "dash":
      return <Dashboard setTitle={setTitle} />
    case "profile":
      return <Profile logout={logout} setTitle={setTitle} />
    case "profile-list":
      return <ProfileList setTitle={setTitle} />
    case "org":
      return <Org setTitle={setTitle} />
    case "org-list":
      return <OrgList setTitle={setTitle} />
    default:
      return <p>whoops nothing to see</p>
  }
}

const App = ({ logout, setTitle }) => {
  const [page, setPage] = useState("dash")
  return (
    <Layout
      setPage={page => {
        setPage(page)
      }}
      page={page}
    >
      <InnerComponent page={page} logout={logout} setTitle={setTitle} />
    </Layout>
  )
}

export default App
