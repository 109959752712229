import React, { useState, useEffect } from "react"
import { useQuery, gql } from "@apollo/client"
import { OrgDetails, innerQuery } from "./org"
import Loading from "../components/loading"
import { Back } from "../components/icons"
import AddOrg from "./add-org"

const OrgList = ({ setTitle }) => {
  const title = "All Organizations"
  const [orgDetail, setOrgDetail] = useState(null)
  useEffect(() => {
    setTitle(orgDetail ? orgDetail.name || "Brokerage" : title)
    return () => {
      setTitle("")
    }
  }, [setTitle, orgDetail])
  const { loading, error, data } = useQuery(All_ORGS)
  if (loading) return <Loading />
  if (error) return <p>Error :(</p>
  let { allOrgs } = data
  if (!allOrgs) return <p>oops nothing to see here</p>
  if (orgDetail) {
    return (
      <>
        <button
          className="btn btn-secondary flex gap-2 mb-4"
          onClick={() => {
            setOrgDetail(null)
          }}
        >
          <Back /> Back to {title}
        </button>
        <OrgDetails org={orgDetail} />
        <button
          className="btn btn-secondary flex gap-2 mt-12"
          onClick={() => {
            setOrgDetail(null)
          }}
        >
          <Back /> Back to {title}
        </button>
      </>
    )
  }
  return (
    <>
      <h1>{title}</h1>
      <div className="flex-nowrap flex items-end justify-between gap-2 pt-2 pb-6">
        <h4>{`Displaying ${allOrgs.length} Organizations`}</h4>
        <AddOrg />
      </div>
      <div className="tablet:grid-cols-3 laptop:grid-cols-5 desktop:grid-cols-7 tablet:gap-4 grid grid-cols-1 gap-3 pt-2">
        {allOrgs.map(org => (
          <button
            className="item tablet:flex-col flex flex-row items-center w-full col-span-1 gap-4 border rounded"
            key={org.id}
            onClick={() => {
              setOrgDetail(org)
            }}
          >
            <img
              className="object-contain w-16 h-16"
              src={org?.imageUrl || ""}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = "icon.svg"
              }}
              alt={`${org?.name || "Brokerage"}'s Logo`}
            />
            <p className="text-left tablet:text-center">{org?.name || "no name"}</p>
          </button>
        ))}
      </div>
    </>
  )
}

const All_ORGS = gql`
    query GetAllOrgs{
      allOrgs {${innerQuery}}
    }
`

export default OrgList
