import React from "react"
import { useQuery, gql } from "@apollo/client"

import Loading from "../components/loading"
import AddStaff from "./add-staff"
import { Details } from "./resp-details"

let StaffList = ({ orgId, className }) => {
  const { loading, error, data } = useQuery(STAFF_LIST, {
    variables: { orgId },
  })
  if (loading) return <Loading />
  if (error) return <p>Error :(</p>
  let {
    getOrg: { staff },
  } = data
  return (
    <section className={`pt-6 pb-1 ${className}`}>
      <Details title="Staff List" keepOpen="laptop">
        <div className="flex-nowrap flex items-end justify-between col-span-2 gap-2 pt-2 pb-6">
          <div className="text-sm">Displaying {staff.length} Staff</div>
          <AddStaff orgId={orgId} />
        </div>
        <div className="flex flex-col col-span-2 gap-2">
          {staff.map(({ id, title, user }) => (
            <div key={id} className="item grid grid-cols-3 py-2">
              <div className="col-span-2">
                <div title="Name">{user.name || "no name"}</div>
                <div title="Email" className="text-sm">
                  {user.email}
                </div>
              </div>
              <div className="text-clip overflow-hidden" title="Job Title">
                {title || "no title"}
              </div>
            </div>
          ))}
        </div>
      </Details>
    </section>
  )
}

const STAFF_LIST = gql`
  query GetOrg($orgId: Float!) {
    getOrg(id: $orgId) {
      id
      staff {
        id
        title
        user {
          id
          name
          email
        }
      }
    }
  }
`

export default StaffList
