import React, {useRef, useState} from "react"
import { gql, useMutation } from "@apollo/client"
import CryptoJS from "crypto-js"

let ImgUpload = ({widgetOpts={}, imageUrl, alt, entityId, entityType}) => {
    let [imgUrl, setImgUrl] = useState(imageUrl) 
    const [updateImg] = useMutation(QUERIES[entityType], {
        variables: { id: entityId, field: "imageUrl" },
    })
    let params = {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD_NAME,
      apiKey: process.env.GATSBY_CLOUDINARY_API_KEY,
      uploadPreset: process.env.GATSBY_CLOUDINARY_PRESET,
      secure:true,
      multiple: false,
      cropping: true,
      publicId: `${entityType}_${entityId}`,
      croppingAspectRatio: 1,
      sources: ["local", "camera", "url"],
      folder: process.env.GATSBY_ENV || "dev",
      clientAllowedFormats: ["png", "jpg", "svg"],
      maxImageFileSize: 2000000, // 2mb max,
      quality: 90, // If possible, reduce quality to save on storage
      uploadSignature: (resultCallback, params) => {
        let data = Object.entries(params)
            .sort(([k1], [k2]) => {
              if(k1 === k2) return 0;
              return (k1<k2)? -1 : 1;
            })
            .map(([key, val]) => `${key}=${val}`)
            .join("&")
        let signature = CryptoJS.SHA256(`${data}${process.env.GATSBY_CLOUDINARY_API_SECRET}`).toString(CryptoJS.enc.Hex)
        resultCallback(signature)
      },
      ...widgetOpts
    };
    let imgWidget = useRef(window && window?.cloudinary.createUploadWidget(
        params,
        (error, result) => {
          if (!error && result && result.event === "success") {
            // console.log("Done! Here is the image info: ", result.info);
            let newUrl = result.info.secure_url
            if(!newUrl) return console.log("uh oh, no new url")
            updateImg({variables: {
                value: newUrl
            }})
            setImgUrl(newUrl) // For the profile list > selected profile
          }
        }
      ))
    let uploadTxt = `Upload new ${entityType === "org"? "logo" : "image"}`
    return (<>
    <div className="relative flex flex-col place-items-center gap-y-2">
        <button 
            className={`relative img-overlay ${entityType === "user"? "rounded-full border overflow-hidden ": ""}`}
            onClick={() => {imgWidget.current.open()}}
        >
            <img
            className="overlay-img object-contain w-48 h-48 cursor-pointer"
            src={imgUrl || ""}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = "anon.png"
            }}
            alt={alt}
            />
            <p className="overlay-txt absolute top-[50%] left-0 w-full capitalize bg-transparent pointer-events-none text-stone-900 text-center -translate-y-[50%] opacity-0 font-semibold">{uploadTxt}</p>
        </button>
        <button 
            className="btn btn-tertiary w-fit laptop:hidden capitalize"
            onClick={() => {imgWidget.current.open()}}
        >{uploadTxt}</button>
    </div>
</>)
}


const UPDATE_USER_IMG = gql`
  mutation UpdateUserField($id: Float!, $field: String!, $value: String!) {
    updateUserField(id: $id, field: $field, value: $value) {
      id
      imageUrl
    }
  }
`

const UPDATE_ORG_LOGO = gql`
  mutation UpdateOrgField($id: Float!, $field: String!, $value: String!) {
    updateOrgField(id: $id, field: $field, value: $value) {
      id
      imageUrl
    }
  }
`

const QUERIES = {
    user: UPDATE_USER_IMG,
    org: UPDATE_ORG_LOGO
}


export default ImgUpload