import React from "react"
import { Helmet } from "react-helmet"

export default function err_404() {
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>Not Found | ModernRealtor</title>
        <meta http-equiv="Content-Type" content="text/html; charSet=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <h1>404 Not Found</h1>
    </div>
  )
}
