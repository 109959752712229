import axios from "axios"

// Returns a promise
export const performLogin = async ({ email, password }) => {
  let data = { email, password }

  return axios
    .post(`${process.env.GATSBY_API_URL}/login`, data, {
      withCredentials: true,
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const performRefresh = async () => {
  return axios
    .post(
      `${process.env.GATSBY_API_URL}/refresh`,
      {},
      { withCredentials: true }
    )
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export const performLogout = async () => {
  return axios.post(
    `${process.env.GATSBY_API_URL}/logout`,
    {},
    { withCredentials: true }
  )
}
