import React, { useState } from "react"
import { useQuery, gql } from "@apollo/client"

function Home() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
    </svg>
  )
}

function User() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        clipRule="evenodd"
      />
    </svg>
  )
}

function Users() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
    </svg>
  )
}

function Collection() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
    </svg>
  )
}

function Briefcase() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
        clipRule="evenodd"
      />
      <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
    </svg>
  )
}

let buttonInfo = {
  dash: {
    text: "Home",
    icon: <Home />,
  },
  profile: {
    text: "Profile",
    icon: <User />,
  },
  "profile-list": {
    text: "All Users",
    icon: <Users />,
  },
  org: {
    text: "Brokerage",
    icon: <Briefcase />,
  },
  "org-list": {
    text: "All Orgs",
    icon: <Collection />,
  },
}

const FETCH_TOP_LVL = gql`
  {
    curUser {
      id
      isSuper
      staff {
        id
        org {
          id
        }
      }
    }
  }
`

const defaultButtons = ["dash", "profile"]
const NavBar = ({ setPage, page }) => {
  const [buttons, setButtons] = useState(defaultButtons)
  const [loaded, setLoaded] = useState(false)

  const { loading, error, data } = useQuery(FETCH_TOP_LVL)
  if (error) {
    console.error(error)
    return <p>Error :(</p>
  }
  if (!loaded && !loading) {
    setLoaded(true)
    let {
      curUser: { isSuper, staff },
    } = data
    let addButtons = []
    if (staff?.org?.id) addButtons.push("org")
    if (isSuper) {
      addButtons.push("profile-list")
      addButtons.push("org-list")
    }
    setButtons(defaultButtons.concat(addButtons))
  }
  return (
    <nav className="bg-stone-800 text-stone-50 border-stone-50 laptop:top-0 laptop:w-fit laptop:h-screen laptop:flex-col laptop:border-0 laptop:rounded-t-none laptop:rounded-r laptop:px-6 laptop:gap-7 desktop:gap-5 laptop:justify-start laptop:pt-10 desktop:px-0 fixed bottom-0 left-0 z-10 flex justify-center w-full gap-5 pb-4 border-t-2 rounded-t">
      {buttons.map(key => (
        <button
          key={key}
          className={`text-xs w-12 h-12 flex flex-col items-center justify-end relative desktop:flex-row desktop:gap-2 desktop:justify-start desktop:w-full desktop:pl-8 desktop:pr-12 desktop:py-6 ${
            key === page ? "selected" : ""
          }`}
          onClick={() => setPage(key)}
        >
          <div className="icon">{buttonInfo[key].icon}</div>
          {buttonInfo[key].text}
        </button>
      ))}
    </nav>
  )
}

export default NavBar
