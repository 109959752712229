import React, { useState, useEffect } from "react"
import { useQuery, gql } from "@apollo/client"
import { ProfileDetails, innerQuery } from "./profile"
import Loading from "../components/loading"
import { Back } from "./icons"

const ProfileList = ({ setTitle }) => {
  const title = "All Users"
  const [profileDetail, setProfileDetail] = useState(null)
  useEffect(() => {
    setTitle(profileDetail ? profileDetail.name || "User" : title)
    return () => {
      setTitle("")
    }
  }, [setTitle, profileDetail])
  const { loading, error, data } = useQuery(All_USERS)
  if (loading) return <Loading />
  if (error) return <p>Error :(</p>
  let { allUsers } = data
  if (!allUsers) return <p>oops nothing to see here</p>
  if (profileDetail) {
    return (
      <>
        <button
          className="btn btn-secondary flex gap-2 mb-4"
          onClick={() => {
            setProfileDetail(null)
          }}
        >
          <Back /> Back to {title}
        </button>
        <ProfileDetails user={profileDetail} />
        <button
          className="btn btn-secondary flex gap-2 mt-12"
          onClick={() => {
            setProfileDetail(null)
          }}
        >
          <Back /> Back to {title}
        </button>
      </>
    )
  }
  return (
    <>
      <h1>{title}</h1>
      <h2 className="mb-4">{`Displaying ${allUsers.length} Users`}</h2>
      <div className="tablet:grid-cols-3 laptop:grid-cols-5 desktop:grid-cols-7 tablet:gap-4 grid grid-cols-1 gap-3 pt-2">
        {allUsers.map(user => (
          <button
            className="item tablet:flex-col flex flex-row items-center w-full col-span-1 gap-4 border rounded"
            key={user.id}
            title={user?.name || "User"}
            onClick={() => {
              setProfileDetail(user)
            }}
          >
            <img
              className="object-contain w-16 h-16 rounded-full"
              src={user?.imageUrl || ""}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = "anon.png"
              }}
              alt={`${user?.name || "User"}'s Headshot`}
            />
            {user?.name || "no name"}
          </button>
        ))}
      </div>
    </>
  )
}

const All_USERS = gql`
    {
      allUsers {${innerQuery}}
    }
`

export default ProfileList
