import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { toast } from "react-toastify"

import { Refresh, EditIcon } from "./icons"

let toastProps = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

function SaveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={3}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
    </svg>
  )
}

function CancelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-4 h-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  )
}

function FormField(props) {
  let [disabled, setDisabled] = useState(true)
  let [oldValue, setOldValue] = useState(props.initValue)
  let [value, setValue] = useState(props.initValue)
  let [loading, setLoading] = useState(false)
  const [updateFormField] = useMutation(QUERIES[props.gqlKey], {
    variables: { id: props.id },
  })
  function submit(event) {
    event.preventDefault()
    setDisabled(true)
    function getValue(val) {
      if (typeof val === "string") return val.trim()
      return val
    }
    if (getValue(value) !== oldValue) {
      setLoading(true)
      updateFormField({
        variables: { field: props.input.id, value: getValue(value) },
      })
        .then(() => {
          setLoading(false)
          setOldValue(getValue(value))
          toast.success("Save Successful", toastProps)
        })
        .catch(err => {
          console.error(err)
          setLoading(false)
          toast.error("Save Failure", toastProps)
          cancel()
        })
    }
  }

  function cancel() {
    setValue(oldValue)
    setDisabled(true)
  }
  let inputProps = {
    ...props.input,
    value,
    onChange: ({ target }) => {
      setValue(target.value)
    },
    disabled,
  }
  return (
    <form onSubmit={submit} className={`pt-2 pb-1 ${props.className || ""}`}>
      <fieldset
        className={`form-field relative flex-row justify-between items-center ${
          disabled ? "disabled" : ""
        } ${loading ? " loading animate-pulse " : ""} `}
      >
        <div className="grow py-0">
          {props.input.type === "textarea" ? (
            <textarea
              {...inputProps}
              onKeyDown={e => {
                if (e.key === "Tab") {
                  e.target.value += "\t"
                  if (e.preventDefault) e.preventDefault()
                }
              }}
            />
          ) : props.input.type === "select" ? (
            <div className={`select-wrapper ${disabled ? "disabled" : ""}`}>
              <select {...inputProps}>
                {Object.entries(props.options || {}).map(([key, val], i) => (
                  <option key={i} value={key}>
                    {val}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <input {...inputProps} />
          )}
          <label className="text-xs" htmlFor={props.input.id}>
            {props.label}
          </label>
        </div>
        {disabled && !loading ? (
          <button
            className="btn relative self-start h-min px-0 pt-0.5 mt-2 text-stone-500 hover:text-slate-700"
            onClick={() => {
              setDisabled(false)
            }}
          >
            <EditIcon />
          </button>
        ) : (
          <>
            <button
              className="btn w-min text-stone-600 hover:text-slate-900 h-min relative self-end p-0 m-0"
              type="submit"
              disabled={disabled}
            >
              {loading ? (
                <div className="animate-spin">
                  <Refresh />
                </div>
              ) : (
                <SaveIcon />
              )}
            </button>
            <button
              onClick={cancel}
              disabled={disabled}
              className={`btn text-stone-500 hover:text-slate-700 absolute right-0 top-0 w-min p-0 h-min ${
                loading ? " hidden " : ""
              }`}
            >
              <CancelIcon />
            </button>
          </>
        )}
      </fieldset>
    </form>
  )
}

const UPDATE_NAME = gql`
  mutation UpdateName($id: Float!, $field: String!, $value: String!) {
    updateUserField(id: $id, field: $field, value: $value) {
      id
      name
    }
  }
`

const UPDATE_FB = gql`
  mutation UpdateContactFb($id: Float!, $field: String!, $value: String!) {
    updateContactField(id: $id, field: $field, value: $value) {
      id
      fb
    }
  }
`

const UPDATE_IG = gql`
  mutation UpdateContactIg($id: Float!, $field: String!, $value: String!) {
    updateContactField(id: $id, field: $field, value: $value) {
      id
      ig
    }
  }
`

const UPDATE_LINKEDIN = gql`
  mutation UpdateContactLinkedIn(
    $id: Float!
    $field: String!
    $value: String!
  ) {
    updateContactField(id: $id, field: $field, value: $value) {
      id
      linkedIn
    }
  }
`

const UPDATE_YT = gql`
  mutation UpdateContactYt($id: Float!, $field: String!, $value: String!) {
    updateContactField(id: $id, field: $field, value: $value) {
      id
      yt
    }
  }
`

const UPDATE_TWITTER = gql`
  mutation UpdateContactTwitter($id: Float!, $field: String!, $value: String!) {
    updateContactField(id: $id, field: $field, value: $value) {
      id
      twitter
    }
  }
`

const UPDATE_CELL = gql`
  mutation UpdateContactCell($id: Float!, $field: String!, $value: String!) {
    updateContactField(id: $id, field: $field, value: $value) {
      id
      fb
    }
  }
`

const UPDATE_BUSINESS = gql`
  mutation UpdateContactBusiness(
    $id: Float!
    $field: String!
    $value: String!
  ) {
    updateContactField(id: $id, field: $field, value: $value) {
      id
      business
    }
  }
`

const UPDATE_EMAIL = gql`
  mutation UpdateContactEmail($id: Float!, $field: String!, $value: String!) {
    updateContactField(id: $id, field: $field, value: $value) {
      id
      email
    }
  }
`

const UPDATE_ADDR = gql`
  mutation UpdateContactAddr($id: Float!, $field: String!, $value: String!) {
    updateContactField(id: $id, field: $field, value: $value) {
      id
      addr
    }
  }
`

const UPDATE_STAFF_ABOUT = gql`
  mutation UpdateStaffAbout($id: Float!, $field: String!, $value: String!) {
    updateStaffField(id: $id, field: $field, value: $value) {
      id
      about
    }
  }
`

const UPDATE_STAFF_TITLE = gql`
  mutation UpdateStaffTitle($id: Float!, $field: String!, $value: String!) {
    updateStaffField(id: $id, field: $field, value: $value) {
      id
      title
    }
  }
`

const UPDATE_STAFF_DISPLAYPV = gql`
  mutation UpdateStaffDisplayOnPv(
    $id: Float!
    $field: String!
    $value: String!
  ) {
    updateStaffField(id: $id, field: $field, value: $value) {
      id
      displayOnPv
    }
  }
`

const UPDATE_ORG_NAME = gql`
  mutation UpdateOrgName($id: Float!, $field: String!, $value: String!) {
    updateOrgField(id: $id, field: $field, value: $value) {
      id
      name
    }
  }
`

const UPDATE_ORG_TAGLINE = gql`
  mutation UpdateOrgTagline($id: Float!, $field: String!, $value: String!) {
    updateOrgField(id: $id, field: $field, value: $value) {
      id
      tagline
    }
  }
`

const UPDATE_ORG_ABOUT = gql`
  mutation UpdateOrgAbout($id: Float!, $field: String!, $value: String!) {
    updateOrgField(id: $id, field: $field, value: $value) {
      id
      about
    }
  }
`

const UPDATE_ORG_DESC = gql`
  mutation UpdateOrgDesc($id: Float!, $field: String!, $value: String!) {
    updateOrgField(id: $id, field: $field, value: $value) {
      id
      description
    }
  }
`

const UPDATE_ORG_GAID = gql`
  mutation UpdateOrgGaId($id: Float!, $field: String!, $value: String!) {
    updateOrgField(id: $id, field: $field, value: $value) {
      id
      gaId
    }
  }
`

const UPDATE_ORG_DOMCOL = gql`
  mutation UpdateOrgDomCol($id: Float!, $field: String!, $value: String!) {
    updateOrgField(id: $id, field: $field, value: $value) {
      id
      dominantColor
    }
  }
`

const UPDATE_ORG_COMCOL = gql`
  mutation UpdateOrgComCol($id: Float!, $field: String!, $value: String!) {
    updateOrgField(id: $id, field: $field, value: $value) {
      id
      complimentColor
    }
  }
`

const QUERIES = {
  user_name: UPDATE_NAME,
  contact_fb: UPDATE_FB,
  contact_ig: UPDATE_IG,
  contact_linkedIn: UPDATE_LINKEDIN,
  contact_yt: UPDATE_YT,
  contact_twitter: UPDATE_TWITTER,
  contact_cell: UPDATE_CELL,
  contact_business: UPDATE_BUSINESS,
  contact_email: UPDATE_EMAIL,
  contact_addr: UPDATE_ADDR,
  staff_about: UPDATE_STAFF_ABOUT,
  staff_title: UPDATE_STAFF_TITLE,
  staff_displayPv: UPDATE_STAFF_DISPLAYPV,
  org_name: UPDATE_ORG_NAME,
  org_tagline: UPDATE_ORG_TAGLINE,
  org_about: UPDATE_ORG_ABOUT,
  org_desc: UPDATE_ORG_DESC,
  org_gaId: UPDATE_ORG_GAID,
  org_domCol: UPDATE_ORG_DOMCOL,
  org_comCol: UPDATE_ORG_COMCOL,
}

export default FormField
