import React from "react"
import FormField from "./form-field"
import { Details } from "./resp-details"

export const ContactFields = ({ contact, className }) => {
  return (
    <section className={`pt-6 pb-1 ${className || ""}`}>
      <Details title="Contact Details" keepOpen="laptop">
        {Object.entries(contact)
          .filter(([key]) => key !== "__typename" && key !== "id")
          .map(([key, val], i) => {
            let autoCompleteOpts = {
              cell: "tel",
              business: "tel",
              email: "email",
              fb: "url",
              ig: "url",
              linkedIn: "url",
              yt: "url",
              twitter: "url",
            }
            let typeOpts = {
              email: "email",
              cell: "tel",
              business: "tel",
            }
            let labelOpts = {
              business: "business number",
              addr: "mailing address",
              yt: "youtube",
              fb: "facebook",
              ig: "instagram",
            }
            let placeholderOpts = {
              fb: "facebook.com/ExampleName",
              ig: "instagram.com/ExampleName",
              linkedIn: "linkedin.com/in/ExampleName",
              yt: "youtube.com/channel/ExampleName",
              twitter: "twitter.com/ExampleName",
              cell: "(416) 888-8888",
              business: "(416) 888-8888",
              email: "ExampleName@email.com",
              addr: "1234 Example Ave., Toronto, ON, Canada, Ontario",
            }
            return (
              <FormField
                key={i}
                input={{
                  id: key,
                  type: typeOpts[key] || "text",
                  autoComplete: autoCompleteOpts[key] || "on",
                  placeholder: placeholderOpts[key],
                }}
                initValue={val || ""}
                label={labelOpts[key] || key}
                gqlKey={`contact_${key}`}
                id={contact.id}
                className="border-b border-dotted"
              />
            )
          })}
      </Details>
    </section>
  )
}
