import React, { useRef, useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { toast } from "react-toastify"

import { Refresh } from "./icons"

let toastProps = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

const AddStaff = ({ orgId }) => {
  let [loading, setLoading] = useState(false)
  const [addStaff] = useMutation(ADD_STAFF, {
    refetchQueries: ["GetAllOrgs", "GetOrg"],
  })

  const myDiag = useRef(null)
  useEffect(() => {
    myDiag.current.addEventListener("close", function close() {
      if (myDiag.current.returnValue !== "submit") return
      let inputName = document.getElementById("staffName")
      let inputEmail = document.getElementById("staffEmail")
      let name = (inputName?.value || "").trim()
      let email = (inputEmail?.value || "").trim()
      inputName.value = ""
      inputEmail.value = ""
      if (name === "" || email === "") return
      setLoading(true)
      addStaff({ variables: { name, email, orgId } })
        .then(() => toast.success("Creation Succesful", toastProps))
        .catch(() => toast.error("Creation Failure", toastProps))
        .finally(() => setLoading(false))
    })
  })

  return (
    <>
      <dialog className="add-popup" ref={myDiag}>
        <h2>Add Staff</h2>
        <form method="dialog">
          <fieldset>
            <div>
              <input
                required
                id="staffName"
                type="text"
                autoComplete="off"
                placeholder="Enter Name of Staff"
                pattern="[\w\s-]+"
                title="Please include at least one alphanumeric character"
              />
              <label htmlFor="staffName">Name</label>
            </div>
            <div>
              <input
                required
                id="staffEmail"
                type="email"
                autoComplete="off"
                placeholder="Enter Email of Staff"
                title="Please Enter Unique Staff Email"
              />
              <label htmlFor="staffEmail">Email</label>
            </div>
            <input
              className="btn btn-tertiary py-1 my-2 capitalize"
              type="submit"
              value="submit"
            />
          </fieldset>
        </form>
      </dialog>
      <button
        onClick={() => {
          if (typeof myDiag.current.showModal === "function") {
            myDiag.current.showModal()
          } else {
            toast.error("Browser Error", toastProps)
          }
        }}
        className={`${
          loading ? " animate-pulse " : ""
        } btn btn-tertiary flex justify-between place-items-center w-16 h-7`}
        disabled={loading}
      >
        {loading ? (
          <span className="animate-spin bg-transparent">
            <Refresh className="w-4 h-4" />
          </span>
        ) : (
          <span className="font-thin h-min pl-0.5">&#10010;</span>
        )}
        <span className="h-fit ">Add</span>
      </button>
    </>
  )
}

const ADD_STAFF = gql`
  mutation AddStaff($orgId: Float!, $name: String!, $email: String!) {
    addStaffNewUser(orgId: $orgId, name: $name, email: $email) {
      id
    }
  }
`

export default AddStaff
