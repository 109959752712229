import React, { useRef, useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { toast } from "react-toastify"

import { innerQuery } from "./org"
import { Refresh } from "./icons"

let toastProps = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

const AddOrg = () => {
  let [loading, setLoading] = useState(false)
  const [addOrg] = useMutation(ADD_ORG, {
    refetchQueries: ["GetAllOrgs"],
  })

  const myDiag = useRef(null)
  useEffect(() => {
    myDiag.current.addEventListener("close", function close() {
      if (myDiag.current.returnValue !== "submit") return
      let input = document.getElementById("orgName")
      let name = input?.value
      input.value = ""
      if (name.trim() === "") return
      setLoading(true)
      addOrg({ variables: { name: name.trim() } })
        .then(() => toast.success("Creation Succesful", toastProps))
        .catch(() => toast.error("Creation Failure", toastProps))
        .finally(() => setLoading(false))
    })
  })

  return (
    <>
      <dialog className="add-popup" ref={myDiag}>
        <h2>Add Organization</h2>
        <form method="dialog">
          <fieldset>
            <div>
              <input
                required
                id="orgName"
                type="text"
                autoComplete="off"
                placeholder="Enter Name of New Org."
                pattern="[\.\w\s-]+"
                title="Please include at least one alphanumeric character"
              />
              <label htmlFor="orgName">Brokerage Name</label>
            </div>
            <input
              className="btn btn-tertiary py-1 my-2 capitalize"
              type="submit"
              value="submit"
            />
          </fieldset>
        </form>
      </dialog>
      <button
        onClick={() => {
          if (typeof myDiag.current.showModal === "function") {
            myDiag.current.showModal()
          } else {
            toast.error("Browser Error", toastProps)
          }
        }}
        className={`${
          loading ? " animate-pulse " : ""
        } btn btn-tertiary flex justify-between place-items-center w-16 h-7`}
        disabled={loading}
      >
        {loading ? (
          <span className="animate-spin bg-transparent">
            <Refresh className="w-4 h-4" />
          </span>
        ) : (
          <span className="font-thin h-min pl-0.5">&#10010;</span>
        )}
        <span className="h-fit ">Add</span>
      </button>
    </>
  )
}

const ADD_ORG = gql`
    mutation AddOrg($name: String!){
        addOrg(name: $name){
            ${innerQuery}
        }
    }
`

export default AddOrg
