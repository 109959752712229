import React, { useState } from "react"

export const Details = ({ children, title, keepOpen }) => {
  let [open, setOpen] = useState(false)
  return (
    <div className={`${open ? "open" : ""} ${`open-${keepOpen}`} details`}>
      <button onClick={() => setOpen(!open)} className="summary">
        <h3>{title || ""}</h3>
      </button>
      <div className="details-content">{children}</div>
    </div>
  )
}
