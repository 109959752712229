import React, { useEffect } from "react"
import { useQuery, gql } from "@apollo/client"

import Loading from "../components/loading"
import FormField from "./form-field"
import { ContactFields } from "./contact-fields"
import { StaffFields } from "./staff-fields"
import ImgUpload from "./img-upload"

export const ProfileDetails = ({ user }) => {
  const { name, imageUrl, contact, staff, id } = user

  return (
    <div className="tablet:grid-cols-2 tablet:gap-y-4 desktop:gap-10 grid grid-cols-1 gap-2">
      <div className="flex justify-center col-span-1">
        <ImgUpload 
          alt={`${name || "User"}'s Headshot`} 
          imageUrl={imageUrl || ""}
          entityId={id} 
          entityType="user"
          widgetOpts={{clientAllowedFormats: ["png", "jpg"]}}
        />
      </div>
      <div className="tablet:mt-3 col-span-1">
        <FormField
          input={{
            id: "name",
            type: "text",
            autoComplete: "name",
            placeholder: "Jane Doe",
          }}
          initValue={name.trim()}
          label="Name"
          gqlKey="user_name"
          id={id}
          className="border-b border-dotted"
        />
      </div>
      {staff ? (
        <StaffFields
          staff={staff}
          className="tablet:col-span-2 desktop:col-span-1 col-span-1"
        />
      ) : (
        <p className="text-stone-800 tablet:text-center col-span-1 pt-4 text-sm italic">
          Not a staff of any organization
        </p>
      )}

      <ContactFields
        contact={contact}
        className="tablet:col-span-2 desktop:col-span-1 col-span-1"
      />
    </div>
  )
}
function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
      />
    </svg>
  )
}
const Profile = ({ logout, setTitle }) => {
  const title = "Profile"
  useEffect(() => {
    setTitle(title)
    return () => {
      setTitle("")
    }
  }, [setTitle])
  const { loading, error, data } = useQuery(CURRENT_USER)
  if (loading) return <Loading />
  if (error) return <p>Error :(</p>
  let { curUser } = data
  return (
    <>
      <h1>{title}</h1>
      <ProfileDetails user={curUser} />
      <button
        className="btn btn-secondary flex gap-2 mt-8"
        onClick={event => {
          event.preventDefault()
          logout()
        }}
      >
        <LogoutIcon /> Logout
      </button>
    </>
  )
}
export let innerQuery = `
        id
        name
        imageUrl
        contact {
          id
          fb
          ig
          linkedIn
          yt
          twitter
          cell
          business
          email
          addr
        }
        staff {
          id
          about
          title
          displayOnPv
          org {
            id
            name
          }
        }`
const CURRENT_USER = gql`
    {
      curUser {${innerQuery}}
    }
`
export default Profile
