import React, { useState, useEffect } from "react"
import axios from "axios"

import LogoSvg from "../../static/icon.svg"
import Loading from "./loading"

function Logo() {
  return (
    <img src={LogoSvg} className="w-28 pt-6 m-auto" alt="ModernRealtor Logo" />
  )
}

function Main(props) {
  const [page, setPage] = useState("login")

  return (
    <>
      <Logo />
      <h2 className="text-slate-800 py-0 text-center">ModernRealtor</h2>
      <h3 className="text-slate-600 pb-6 text-center">Broker Portal</h3>
      {page === "login" ? (
        <Login
          {...props}
          forgotPass={() => {
            setPage("forgot")
          }}
        />
      ) : (
        <ForgotPassword
          {...props}
          toLogin={() => {
            setPage("login")
          }}
        />
      )}
    </>
  )
}

function ForgotPassword({ toLogin, setTitle }) {
  const [done, setDone] = useState(false)
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTitle("Forgot Password")
    return () => {
      setTitle("")
    }
  }, [setTitle])

  async function submit(event) {
    setLoading(true)
    event.preventDefault()
    event.target.firstChild.toggleAttribute("disabled")
    return axios
      .post(`${process.env.GATSBY_API_URL}/forgotPassword`, { email })
      .catch(err => {
        console.log(err)
        return Promise.resolve()
      })
      .finally(() => {
        setDone(true)
        setLoading(false)
      })
  }

  return (
    <>
      <h1>Forgot Password</h1>
      {done ? (
        <div>
          <p>
            Check your inbox. If an account with this email exists, a password
            reset link has been sent.
          </p>
          <button onClick={toLogin} className="btn btn-secondary self-start">
            Back to Login
          </button>
        </div>
      ) : loading ? (
        <Loading />
      ) : (
        <form onSubmit={submit}>
          <fieldset>
            <div className="pt-6">
              <input
                required
                type="email"
                id="email"
                autoComplete="email"
                placeholder="Enter Account Email"
                onChange={({ target: { value } }) => {
                  setEmail(value)
                }}
              />
              <label htmlFor="email">Email</label>
            </div>
            <input
              type="submit"
              value="Submit"
              className="btn btn-primary mt-6"
            />
            <button onClick={toLogin} className="btn btn-secondary self-start">
              Back to Login
            </button>
          </fieldset>
        </form>
      )}
    </>
  )
}

function Login({ forgotPass, setTitle, login }) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTitle("Login")
    return () => {
      setTitle("")
    }
  }, [setTitle])

  let handleUpdate = event => {
    if (event.target.id === "email") setEmail(event.target.value)
    else if (event.target.id === "password") setPassword(event.target.value)
  }

  let handleSubmit = async event => {
    setLoading(true)
    event.preventDefault()
    event.target.firstChild.toggleAttribute("disabled")
    await login({ email, password })
      .catch(err => {
        setError(true)
        event.target.firstChild.toggleAttribute("disabled")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <h1>Log in</h1>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="flex flex-col justify-center h-12 text-center">
            {error ? <p>Email or Password is incorrect</p> : ""}
          </div>

          <form onSubmit={handleSubmit}>
            <fieldset>
              <div>
                <input
                  required
                  id="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Enter Email Address"
                  onChange={handleUpdate}
                />
                <label htmlFor="email">Email</label>
              </div>
              <div>
                <input
                  required
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  placeholder="Enter Password"
                  onChange={handleUpdate}
                />
                <label htmlFor="password">Password</label>
              </div>
              <input
                type="submit"
                value="Log In"
                className="btn btn-primary mt-6"
              />
              <button
                onClick={forgotPass}
                className="btn btn-secondary self-start"
              >
                Forgot Password?
              </button>
            </fieldset>
          </form>
        </>
      )}
    </>
  )
}

export default Main
