import React, { useEffect } from "react"

import NavBar from "./nav-bar"
import { ToastContainer } from "react-toastify"

const Layout = ({ children, setPage, page }) => {
  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://upload-widget.cloudinary.com/global/all.js";
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (<div className="flex">
    <NavBar setPage={setPage} page={page} />
    <ToastContainer
      className="px-8 py-4 mb-4 bg-transparent"
      position="top-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={true}
      draggable
      pauseOnHover
    />
    <div
      id="content"
      className="p-6 laptop:ml-32 laptop:mr-12 desktop:ml-44 desktop:mr-16 w-full max-w-md tablet:max-w-3xl laptop:max-w-7xl desktop:max-w-[108rem] mx-auto"
    >
      {children}
    </div>
  </div>
)}
export default Layout
