import React from "react"
import axios from "axios"
import { Helmet } from "react-helmet"

import ValidInput from "../../components/input-valid-item"
import Loading from "../../components/loading"

async function sendRequest(token, email, password) {
  let data = { token, email, password }
  return axios.post(`${process.env.GATSBY_API_URL}/resetPassword`, data)
}

export default function Main({ params }) {
  let token = params.token
  let title = "Reset Your Password"
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{`${title} | ModernRealtor`}</title>
        <meta http-equiv="Content-Type" content="text/html; charSet=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <h1>{title}</h1>
      {token ? <Form token={token} /> : <Invalid />}
    </div>
  )
}

function Invalid() {
  return (
    <div>
      <h2>Invalid Request</h2>
      <p>
        If you believe this is a mistake, please contact support at{" "}
        <a href="mailto:contact@modernrealtor.dev">contact@modernrealtor.dev</a>
      </p>
    </div>
  )
}

function Success() {
  return (
    <div>
      <h2>Success!</h2>
      <p>Password updated successfully. You may now close this window.</p>
    </div>
  )
}

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      newPassword: "",
      confirmPass: "",
      error: false,
      success: false,
      loading: false,
    }
    this.token = props.token
  }

  handleUpdate = event => {
    if (["email", "newPassword", "confirmPass"].includes(event.target.id)) {
      this.setState({
        [event.target.id]: event.target.value,
      })
    }
  }

  handleSubmit = async event => {
    this.setState({ loading: true })
    event.preventDefault() // Stops page from reloading page with form results
    event.target.firstChild.toggleAttribute("disabled")
    await sendRequest(this.token, this.state.email, this.state.newPassword)
      .then(() => {
        this.setState({ success: true })
      })
      .catch(err => {
        this.setState({ error: true })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    if (this.state.loading) return <Loading />
    if (this.state.error) return <Invalid />
    if (this.state.success) return <Success />
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <fieldset>
            <div>
              <input
                required
                type="email"
                id="email"
                autoComplete="email"
                placeholder="Enter Account Email"
                onChange={this.handleUpdate}
              />
              <label htmlFor="email">Email</label>
            </div>
            <div>
              <input
                required
                type="password"
                id="newPassword"
                autoComplete="new-password"
                placeholder="Enter New Password"
                aria-label="New Password should be between 8-15 characters in length and requires an uppercase letter, lowercase letter, a special character and a number"
                pattern="^(?=.{8,15}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[!@#$%^&*\w]*$"
                onChange={this.handleUpdate}
              />
              <label htmlFor="newPassword">Password</label>
            </div>
            <span className="flex-col">
              <ValidInput
                text="8-15 characters long"
                isValid={() => /^.{8,15}$/.test(this.state.newPassword)}
              />
              <ValidInput
                text="Contains a lowercase letter"
                isValid={() => /.*[a-z]/.test(this.state.newPassword)}
              />
              <ValidInput
                text="Contains an uppercase letter"
                isValid={() => /.*[A-Z]/.test(this.state.newPassword)}
              />
              <ValidInput
                text="Contains a special character (!@#$%^&*)"
                isValid={() => /.*[!@#$%^&*]/.test(this.state.newPassword)}
              />
              <ValidInput
                text="Contains a number"
                isValid={() => /.*[0-9]/.test(this.state.newPassword)}
              />
              <ValidInput
                text="No invalid characters"
                isValid={() => /^[!@#$%^&*\w]*$/.test(this.state.newPassword)}
              />
            </span>
            <div>
              <input
                required
                type="password"
                id="confirmPass"
                autoComplete="off"
                placeholder="Confirm New Password"
                aria-label="Retype your new password to confirm"
                pattern={this.state.newPassword}
                onChange={this.handleUpdate}
              />
              <label htmlFor="confirmPass">Confirm Password</label>
            </div>
            <span>
              <ValidInput
                text="Matches password"
                isValid={() =>
                  this.state.newPassword === this.state.confirmPass
                }
              />
            </span>
            <input
              type="submit"
              value="Reset Password"
              className="btn btn-primary"
            />
          </fieldset>
        </form>
      </>
    )
  }
}
